.raise-incident {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;

  &__portal {
    z-index: 2;
  }

  &__heading {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
  }

  &__subheading {
    margin: 8px 0 0;
    @extend .font-size-30;
    color: 400;
    color: $grey-60;
  }

  &__message {
    margin: 20px 0 0;
  }

  &__required {
    margin: 0;
  }

  &__asterisk,
  &__info {
    display: inline-block;
    vertical-align: top;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__asterisk {
    margin-right: 6px;
    color: $orange-60;
  }

  &__content {
    max-height: calc(100vh - 40px);
    margin: 0 auto;
    overflow-y: auto;
  }

  &__message + &__row {
    margin-top: 20px;
  }

  &__row {
    margin-top: 30px;

    &--group {
      margin-top: 40px;
    }

    &--additional {
      margin-top: 20px;
    }
  }

  &__row--no-padding {
    padding: 0;
    margin-right: 0;
    margin-left: 0;
  }

  &__col {
    margin-top: 20px;
    font-size: 0;

    &--no-margin,
    &--details {
      margin-top: 0;
    }
  }

  &__col--contact,
  &__col--additional {
    position: relative;
    margin-top: 10px;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  &__col--contact:nth-child(2) {
    margin-top: 0;
  }

  &__col--additional:nth-child(3):before,
  &__col--contact:nth-child(2):before,
  &__col--contact:nth-child(4):before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 7px;
    height: 1px;
    border-top: 1px solid $grey-60;
    transform: translate(-4px, -50%);
  }

  &__col--contact:nth-child(2):before {
    top: calc(50% + 13px);
  }

  &__col--no-margin + &__col--no-margin {
    margin-top: 20px;
  }

  &__loading {
    display: flex;
    min-height: 160px;
    width: 100%;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }

  &__label {
    @extend .font-size-20;
  }

  &__selection {
    margin-top: 8px;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 17px;
    width: 9px;
    height: 9px;
    transform: translateY(-50%);
    color: $grey-60;
  }

  &__input-group--hidden,
  &__select-group--hidden {
    padding-top: 18px;

    .input-group__label,
    .select-group__label {
      display: none;
    }
  }

  &__footer {
    margin-top: 40px;
    text-align: right;
  }

  &__button + &__button {
    margin-left: 12px;
  }

  &__button--submit {
    min-width: 64px;
  }

  &__star {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }

  &__add-wrap {
    margin-top: 20px;
    text-align: right;
  }

  &__add {
    background-color: $white;
  }
}
